const checkRef = false
const checkToken = false
const timeoutMessage = 'Token Expired'
const baseUrl = 'https://myavzgskfa.execute-api.us-east-1.amazonaws.com';
const apiURI = '/Dev/register_new_client'
const apiKey = 'VYXxmRA_BT1EAYjpC1HkiRyHrxhR_f0wadYsj0W8NQwD1QSohXiGbcPsvbwXFx5Lh11vvdeI0cv8jAijflVgtw'
const form = document.getElementById('form-284630275');

function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const formToJSON = (elements) => [].reduce.call(elements, (data, element) => {
  if(element.name) {
    data[element.name] = element.value;
  }
  return data;
}, {});

const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

form.addEventListener('submit', function(e){
  e.preventDefault()

  let invalidFields = document.querySelectorAll('.invalid')
  if(invalidFields.length) {
    return;
  }

  document.querySelectorAll('input[type="submit"]').forEach(node => {
    node.disabled = true
  })

  const postUrl = `${baseUrl}${apiURI}`;

  const data = formToJSON(form.elements);

  data.marketplace_token = getUrlParameter('x-amzn-marketplace-token');

  const xhr = new XMLHttpRequest();

  xhr.open('POST', postUrl, true);
  xhr.setRequestHeader('authorizationToken', apiKey)
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify(data));

  xhr.onreadystatechange = () => {
    if (xhr.readyState == XMLHttpRequest.DONE) {
      if(xhr.status === 200) {
        document.querySelector('.popup--form').style.display = 'block'
        document.querySelector('body').classList.add('nooverflow')
        let p = document.createElement("p")
        p.append('Thanks for registration')
        document.querySelector('.popup').append(p)
      } else if(xhr.status === 400 && xhr.response.message === timeoutMessage) {
        document.querySelector('.popup--form').style.display = 'block'
        document.querySelector('body').classList.add('nooverflow')
        let p = document.createElement("p")
        p.append('You have taken too long to fill in the form, your session has expired, please ')
        let a = document.createElement("a")
        a.append('try again.')
        a.classList.add('regular-link')
        a.href = "https://aws.amazon.com/marketplace/pp/prodview-ubju7vmpboizu"
        p.append(a)
        document.querySelector('.popup').append(p)
      } else {
        document.querySelector('.popup--form').style.display = 'block'
        document.querySelector('body').classList.add('nooverflow')
        let p = document.createElement("p")
        p.append('There has been an issue, please ')
        let a = document.createElement("a")
        a.append('try again')
        a.classList.add('regular-link')
        a.href = "https://aws.amazon.com/marketplace/pp/prodview-ubju7vmpboizu"
        p.append(a)
        p.append(' or if the issue persist contact us: ')
        let mail = document.createElement("a")
        mail.append('CloudSOCEssentials@capgemini.com')
        mail.classList.add('regular-link')
        mail.href = "mailto:CloudSOCEssentials@capgemini.com"
        p.append(mail)
        document.querySelector('.popup').append(p)
      }
    }
  };
})

// document.querySelectorAll('.popup-close, .popup-overlay').forEach(function(node){
//   node.addEventListener('click', function(e){
//     document.querySelector('.popup--form').style.display = 'none'
//     document.querySelector('body').classList.remove('nooverflow')
//   })
// })



// --- Tooltip ---

function getOffset (element, horizontal = false) {
  if(!element) return 0;
  return getOffset(element.offsetParent, horizontal) + (horizontal ? element.offsetLeft : element.offsetTop);
}

function tooltipBubblePlacer (myTooltip) {
  var myLeft = getOffset(myTooltip, true) + myTooltip.offsetWidth + 21 - myTooltip.querySelector('.tooltip-bubble').offsetWidth,
      myTop,
      supportPageOffset = window.pageXOffset !== undefined,
      isCSS1Compat = ((document.compatMode || "") === "CSS1Compat"),
      scrollTop = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
  if (myTooltip.classList.contains('tooltip--bottom')){
    myTop = getOffset(myTooltip.querySelector('.tooltip-link')) - scrollTop + myTooltip.querySelector('.tooltip-link').offsetHeight
  } else {
    myTop = getOffset(myTooltip.querySelector('.tooltip-link')) - scrollTop - myTooltip.querySelector('.tooltip-bubble').offsetHeight
  }
  myTooltip.querySelector('.tooltip-bubble').style.left = myLeft + 'px'
  myTooltip.querySelector('.tooltip-bubble').style.top = myTop + 'px'
}

// Click "onclick (fixed & normal)" version

document.querySelectorAll('.tooltip--onclick .tooltip-link').forEach(function(node){
  node.addEventListener('click', function(e){
    var myTooltip = node.closest('.tooltip')
    myTooltip.classList.toggle('active')
    if (myTooltip.classList.contains('tooltip--fixed')) {
      tooltipBubblePlacer(myTooltip)
    }
  })
})

// Hover "hovered & fixed" version

document.querySelectorAll('.tooltip--fixed:not(.tooltip--onclick) .tooltip-link').forEach(function(node){
  node.addEventListener('mouseenter', function(e){
    var myTooltip = node.closest('.tooltip')
    tooltipBubblePlacer(myTooltip)
  })
})

// Hide tooltip when we clicked outside

document.addEventListener('mouseup', function(e){
  var me = e.target,
      myParents = []
  while (me) {
    myParents.unshift(me)
    me = me.parentNode
  }
  document.querySelectorAll('.tooltip').forEach(function(node){
    var tooltipContainer = node
    if (tooltipContainer != e.target && ! myParents.includes(tooltipContainer)){
      tooltipContainer.classList.remove('active')
    }
  })
})



// --- Form ---

// Get the input length

// document.querySelectorAll('textarea, input').forEach(function(node){
//   node.addEventListener('keyup', function(e){
//     node.closest('.form-block').querySelector('.form-block-chars span').textContent = node.value.length
//   })
// })

// Focus, full

document.querySelectorAll('input:not([type="submit"]), textarea').forEach(function(node){
  node.addEventListener('focusin', function(e){
    node.closest('.form-block').classList.add('focus')
  })
})

document.querySelectorAll('input:not([type="submit"]), textarea').forEach(function(node){
  node.addEventListener('focusout', function(e){
    node.closest('.form-block').classList.remove('focus')

    if (node.value !== ''){
      node.closest('.form-block').classList.add('full')
    } else {
      node.closest('.form-block').classList.remove('full')
    }
  })
})

document.querySelectorAll('.email-input').forEach(function(node){
  node.addEventListener('keyup', debounce(() => {
    let emails = node.value.split(',');

    let val = emails.some(elem => !validateEmail(elem))
    if (val){
      node.closest('.form-block').classList.add('invalid')
    } else {
      node.closest('.form-block').classList.remove('invalid')
    }
  }))
})

const validateEmail = (email) => {
  return String(email)
    .trim()
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


// --- Select ---

let slideUp = (target, duration=400) => {
  target.style.transitionProperty = 'height, margin, padding'
  target.style.transitionDuration = duration + 'ms'
  target.style.boxSizing = 'border-box'
  target.style.height = target.offsetHeight + 'px'
  target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout( () => {
    target.style.display = 'none'
    target.style.removeProperty('height')
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}
let slideDown = (target, duration=400) => {
  target.style.removeProperty('display')
  let display = window.getComputedStyle(target).display
  if (display === 'none')
    display = 'block'
  target.style.display = display
  let height = target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = 'border-box'
  target.style.transitionProperty = "height, margin, padding"
  target.style.transitionDuration = duration + 'ms'
  target.style.height = height + 'px'
  target.style.removeProperty('padding-top')
  target.style.removeProperty('padding-bottom')
  target.style.removeProperty('margin-top')
  target.style.removeProperty('margin-bottom')
  window.setTimeout( () => {
    target.style.removeProperty('height')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}
var slideToggle = (target, duration = 400) => {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

// Create selects

document.querySelectorAll('select').forEach(function(node) {
  node.classList.add('base-select')
  var mySelectedOptions = node.selectedOptions

  var optionsTable = [],
      optionSelected = 0,
      optionDisabled = 0

  // Add all option to the table
  node.querySelectorAll('option').forEach(function(node2) {
    for (var i = 0; i < mySelectedOptions.length; ++i) {
      if (node2 == mySelectedOptions[i]) {
        optionSelected = 1
        break
      } else {
        optionSelected = 0
      }
    }
    if(node2.disabled){
      optionDisabled = 1
    } else {
      optionDisabled = 0
    }
    optionsTable.push({ value: node2.value, text: node2.textContent, selected: optionSelected, disabled: optionDisabled })
  })

  // Create a new selectyummy

  let newSelectDiv = document.createElement('div'),
      newSelectButton = document.createElement('button'),
      newSelectUl = document.createElement('ul')
  newSelectDiv.classList.add('selectyummy')
  newSelectButton.classList.add('selectyummy-select')
  newSelectUl.classList.add('selectyummy-options')
  newSelectDiv.appendChild(newSelectButton)
  newSelectDiv.appendChild(newSelectUl)
  node.after(newSelectDiv)
  if (node.multiple){
    newSelectDiv.classList.add('selectyummy-multiple')
  }

  // Add options to selectyummy
  var selectedOption = false,
      selectString = ''
  for(var i=0; i < optionsTable.length; ++i){
    var optionClass= '',
        newOption = document.createElement('li')

    // Selected option
    if(optionsTable[i].selected){
      newOption.classList.add('active')
      selectedOption = true
      if(selectString != '') {
        selectString = selectString + ', '
      }
      selectString = selectString + optionsTable[i].text
      newSelectButton.textContent = selectString

    // No selected options and a placeholder
    } else if (node.getAttribute('placeholder') && (optionsTable[0].text == '' || node.multiple) && (! selectedOption || optionsTable[0].selected)) {
      newSelectButton.textContent = node.getAttribute('placeholder')
    }

    // Disabled option
    if (optionsTable[i].disabled){
      newOption.classList.add('disabled')
    }

    newOption.setAttribute('data-value', optionsTable[i].value)
    newOption.textContent = optionsTable[i].text
    newSelectUl.appendChild(newOption)
  }

  // If you want to block the selectyummy's width - use this line:
  // newSelectDiv.style.width = newSelectDiv.innerWidth + 'px'
})

// Click a select

document.querySelectorAll('.selectyummy-select').forEach(function(node) {
  node.addEventListener('click', function(e) {
    e.preventDefault()
    slideToggle(node.nextElementSibling)
    node.parentElement.classList.toggle('active')
  })
})

// Select changer

document.querySelectorAll('.base-select').forEach(function(node) {
  node.addEventListener('change', function(e) {
    var mySelectYummy = node.nextElementSibling,
        baseSelect = node

    // Single select activates clicked options
    if (! mySelectYummy.classList.contains('selectyummy-multiple')){
      var activeOption = mySelectYummy.querySelector('li[data-value="' + baseSelect.value + '"]')
      mySelectYummy.querySelectorAll('.selectyummy-options li').forEach(function(node1) {
        node1.classList.remove('active')
      })
      activeOption.classList.add('active')
      mySelectYummy.querySelector('.selectyummy-select').textContent = activeOption.textContent
      if (baseSelect.getAttribute('placeholder') && activeOption.textContent == ''){
        mySelectYummy.querySelector('.selectyummy-select').textContent = baseSelect.getAttribute('placeholder')
      }

    // Multiple select adds option name to the list
    } else {
      mySelectYummy.querySelectorAll('.selectyummy-options li').forEach(function(node1) {
        node1.classList.remove('active')
      })
      var optionsSelected = []
      for (var i = 0; i < baseSelect.options.length; ++i) {
        if (baseSelect.options[i].selected) {
          optionsSelected.push(baseSelect.options[i].value || baseSelect.options[i].text)
        }
      }
      for (var i = 0; i < optionsSelected.length; ++i) {
        var clicked = mySelectYummy.querySelector('li[data-value="' + optionsSelected[i] + '"]').classList.add('active')
      }
      var selectString = ''

      mySelectYummy.querySelectorAll('.selectyummy-options li').forEach(function(selectOption) {
        if(selectOption.classList.contains('active')){
          if(selectString != '') {
            selectString = selectString + ', '
          }
          selectString = selectString + selectOption.textContent
        }
      })

      mySelectYummy.querySelector('.selectyummy-select').textContent = selectString
      if (baseSelect.getAttribute('placeholder') && selectString == ''){
        mySelectYummy.querySelector('.selectyummy-select').textContent = baseSelect.getAttribute('placeholder')
      }
    }
  })
})

// Click a dropdown item

document.querySelectorAll('.selectyummy-options li').forEach(function(node) {
  node.addEventListener('click', function(e) {
    var mySelectYummy = node.closest('.selectyummy'),
        myBaseSelect = mySelectYummy.previousElementSibling

    if(! mySelectYummy.classList.contains('selectyummy-multiple')){
      myBaseSelect.value = this.getAttribute('data-value')
      // Single selects should close when the option is clicked
      slideUp(mySelectYummy.querySelector('.selectyummy-options'))
      mySelectYummy.classList.remove('active')
    } else {
      if (myBaseSelect.querySelector('option[value="' + node.getAttribute('data-value') + '"]').selected == false) {
        myBaseSelect.querySelector('option[value="' + node.getAttribute('data-value') + '"]').selected = true
      } else {
        myBaseSelect.querySelector('option[value="' + node.getAttribute('data-value') + '"]').selected = false
      }
    }

    if ('createEvent' in document) {
      var evt = document.createEvent('HTMLEvents')
      evt.initEvent('change', false, true)
      myBaseSelect.dispatchEvent(evt)
    }
    else {
      myBaseSelect.fireEvent('onchange')
    }
  })
})

// Hide dropdown when we clicked outside

document.addEventListener('mouseup', function(e){
  var selectContainer = document.querySelector('.selectyummy.active')
  if (selectContainer) {
    var me = e.target,
        myParents = []
    while (me) {
      myParents.unshift(me)
      me = me.parentNode
    }
    if (selectContainer != e.target && ! myParents.includes(selectContainer)){
      slideUp(selectContainer.querySelector('.selectyummy-options'))
      selectContainer.classList.remove('active')
    }
  }
})

// Keyboard

document.addEventListener('keydown', function(e){
  if ((e.keyCode === 40 || e.keyCode === 38) && document.querySelector('.selectyummy.active')) { // Down or Up
    if (selectContainer) {
      e.preventDefault()
      var me = document.querySelector('.selectyummy.active'),
          currentHoveredOption = me.querySelector('.selectyummy-options li.hover'),
          newHoveredOption,
          options = me.querySelectorAll('.selectyummy-options li:not(.disabled)')

      // The last and first element
      if (! currentHoveredOption) {
        if (e.keyCode === 40) { // down
          newHoveredOption = options[0]
        } else { // up
          newHoveredOption = options[options.length - 1]
        }

      // Use keys to go to the option
      } else {
        if (e.keyCode === 40) { // down
          while (currentHoveredOption) {
            if (currentHoveredOption.nextElementSibling) {
                if (currentHoveredOption.nextElementSibling.classList.contains('disabled')) {
                  currentHoveredOption = currentHoveredOption.nextElementSibling
                } else {
                  newHoveredOption = currentHoveredOption.nextElementSibling
                  break
                }
            } else {
              newHoveredOption = options[0]
              break
            }
          }
        } else { // up
          while (currentHoveredOption) {
            if (currentHoveredOption.previousElementSibling) {
                if (currentHoveredOption.previousElementSibling.classList.contains('disabled')) {
                  currentHoveredOption = currentHoveredOption.previousElementSibling
                } else {
                  newHoveredOption = currentHoveredOption.previousElementSibling
                  break
                }
            } else {
              newHoveredOption = options[options.length - 1]
              break
            }
          }
        }

        me.querySelector('.selectyummy-options li.hover').classList.remove('hover')
      }

      // Hover the option
      newHoveredOption.classList.add('hover')

      // Scroll the box to the option so we can see everything
      var offset = newHoveredOption.offsetTop
      me.querySelector('.selectyummy-options').scroll({
        top: offset,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  if (e.keyCode === 13 && document.querySelector('.selectyummy.active')) { // Enter
    // When option is hovered, Enter will click it
    e.preventDefault()
    document.querySelector('.selectyummy.active .selectyummy-options li.hover').click()
  }

  if (e.keyCode === 9 && document.querySelector('.selectyummy.active')) { // Tab
    // When selectyummy is opened, tab closes and deactivates it
    slideUp(document.querySelector('.selectyummy.active .selectyummy-options'))
    document.querySelector('.selectyummy.active').classList.remove('active')
  }
})

// Reset the 'hover' class (needed for keyboard) when we truly hover another element

document.querySelectorAll('.selectyummy.active .selectyummy-options li').forEach(function(node) {
  node.addEventListener('mouseenter', function(e) {
    document.querySelector('.selectyummy.active .selectyummy-options li.hover').removeClass('hover')
  })
})

window.addEventListener("load", (event) => {
  var ref = document.referrer;
  if((checkRef && !ref.match(/^https?:\/\/([^\/]+\.)?aws\.amazon\.com\/marketplace\/(\/|$)/i)) || (checkToken && !getUrlParameter('x-amzn-marketplace-token'))) {
    window.location = 'https://aws.amazon.com/marketplace/pp/prodview-ubju7vmpboizu'
  }
});
